@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.limit-input {
  padding-left: 15px;
  width: 30px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}
